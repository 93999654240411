<template>
  <div class="register-panel">
    <div class="d-block">
      <HeaderPanel title="ลงทะเบียน" :hasFilter="false" class="mb-3" />
      <div v-if="isLoading" class="loading-panel">
        <OtherLoading />
      </div>
      <div v-else-if="isAddOptionalTelephone" class="register-box p-3">
        <b-row no-gutters>
          <b-col cols="7" class="pr-3">
            <InputText
              type="number"
              textFloat=" "
              v-model="telList[0].tel"
              placeholder="เบอร์โทรศัพท์สำรอง"
              :isValidate="$v.telList.$each[0].tel.$error"
              :v="$v.telList.$each[0].tel"
            >
            </InputText>
          </b-col>
          <b-col cols="3">
            <InputText
              type="number"
              textFloat=" "
              placeholder="เบอร์ต่อ"
              v-model="telList[0].ext"
              :disabled="!telList[0].tel"
            ></InputText>
          </b-col>
          <b-col
            cols="2"
            class="w-100 text-center d-flex justify-content-center align-items-center mt-4"
          >
            <font-awesome-icon
              icon="trash-alt"
              title="delete-btn"
              size="lg"
              class="pointer text-primary icon-delete"
              @click.stop="deleteTel(0)"
            />
          </b-col>
          <template v-for="(item, index) in telList" v-if="index != 0">
            <b-col cols="7" class="pr-3">
              <InputText
                type="number"
                textFloat=" "
                v-model="item.tel"
                placeholder="เบอร์โทรศัพท์สำรอง"
                :isValidate="$v.telList.$each[index].tel.$error"
                :v="$v.telList.$each[index].tel"
              >
              </InputText>
            </b-col>
            <b-col cols="3">
              <InputText
                type="number"
                textFloat=" "
                placeholder="เบอร์ต่อ"
                v-model="item.ext"
                :disabled="!item.tel"
              ></InputText>
            </b-col>
            <b-col
              cols="2"
              class="w-100 text-center d-flex justify-content-center align-items-center mt-4"
            >
              <font-awesome-icon
                icon="trash-alt"
                title="delete-btn"
                size="lg"
                class="pointer text-primary icon-delete"
                @click.stop="deleteTel(index)"
              />
            </b-col>
          </template>
        </b-row>
        <div class="d-flex justify-content-center">
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-100 mt-3"
            @click="addOptinalTel"
            :disabled="
              isLoading ||
              (telList.length == limitOptionalTel && limitOptionalTel !== '')
            "
          >
            เพิ่มเบอร์โทรศัพท์สำรอง 
            <!-- ( {{ telList.length }} /
            {{ limitOptionalTel }} ) -->
          </b-button>
        </div>
      </div>
      <RegisterFields
        v-else
        ref="registerFields"
        isMobile
        :form="form"
        :fields="fields"
        :dynamicField="dynamicField"
        @AddOptionalTelephone="AddOptionalTelephone"
      />
    </div>
    <div
      class="d-flex justify-content-between w-100 my-0 mobile-footer"
      v-if="isAddOptionalTelephone"
    >
      <b-button
        variant="close"
        class="btn-modal btn-hover w-50 mr-1"
        @click="closeOptionalPhone"
        :disabled="isLoading"
      >
        ย้อนกลับ
      </b-button>
      <b-button
        variant="primary-color"
        class="btn-modal btn-hover w-50 ml-1"
        @click="saveOptionalPhone"
        :disabled="isLoading"
      >
        บันทึก
      </b-button>
    </div>
    <div class="d-flex justify-content-between w-100 my-0 mobile-footer" v-else>
      <b-button
        variant="close"
        class="btn-modal btn-hover w-50 mr-1"
        @click="$router.push('/')"
        :disabled="isLoading"
      >
        ยกเลิก
      </b-button>
      <b-button
        variant="primary-color"
        class="btn-modal btn-hover w-50 ml-1"
        @click="save"
        :disabled="isLoading"
      >
        ยืนยัน
      </b-button>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputRadioGroup from "@/components/inputs/InputRadioGroup";
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
import HeaderPanel from "@/components/HeaderPanel";
import RegisterFields from "@/components/field/RegisterFields.vue";
import OtherLoading from "@/components/loading/OtherLoading";
import { minLength, maxLength } from "vuelidate/lib/validators";

import { mapGetters } from "vuex";

export default {
  components: {
    OtherLoading,
    InputText,
    InputDatePickerFilter,
    InputRadioGroup,
    HeaderPanel,
    RegisterFields,
  },

  data() {
    return {
      isLoading: false,
      showModal: false,
      tab: 0,
      form: {
        prefix: null,
        subdistrict: null,
        district: null,
        province: null,
        email: "",
        country: null,
        custom_field: [],
        gender: null,
      },
      isAddOptionalTelephone: false,
      limitOptionalTel: null,
      telList: [],
      now: null,
      fields: [],
      dynamicField: [],
    };
  },

  validations() {
    return {
      telList: {
        $each: {
          tel: {
            maxLength: maxLength(10),
            minLength: minLength(10),
          },
        },
      },
    };
  },

  created() {
    this.now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");
    this.limitOptionalTel = this.$store.state.limit_secondary_telephone;
    this.telList.push({ tel: "", ext: "" });
    this.getField();
  },
  computed: {
    ...mapGetters({
      dateTimeFix: "getDateTimeFix",
      branchId: "getBranchId",
    }),
  },

  watch: {
    telList: {
      handler(newVal) {
        newVal.forEach((item) => {
          if (!item.tel) {
            item.ext = "";
          }
        });
      },
      deep: true,
    },
  },

  methods: {
    async getField() {
      this.isLoading = true;
      const data = await this.$axios.get(
        `${this.$baseUrl}/customer/registerform/1`
      );

      if (data.result === 1) {
        this.fields = data.detail.result.fields.filter((el) => el.is_show == 1);

        this.dynamicField = data.detail.result.custom_field.filter(
          (el) => el.is_show == 1
        );

        for (const field of data.detail.result.custom_field) {
          if (field.field_type_id == 2) {
            field.user_answer = [];
          } else {
            field.user_answer = "";
          }
        }
      }
      this.isLoading = false;
    },
    async save() {
      this.$refs.registerFields.save();
    },
    addOptinalTel() {
      if (
        !this.limitOptionalTel ||
        this.telList.length < this.limitOptionalTel
      ) {
        this.telList.push({ tel: "", ext: "" });
      }
    },
    AddOptionalTelephone() {
      this.isAddOptionalTelephone = true;
    },
    closeOptionalPhone() {
      this.telList = [];
      this.telList.push({ tel: "", ext: "" });
      this.$v.$reset();
      this.isAddOptionalTelephone = false;
    },
    saveOptionalPhone() {
      this.$v.telList.$touch();
      if (!this.$v.$error) {
        this.telList = this.telList.filter((item) => item.tel.trim() !== "");

        if (this.telList.length === 0) {
          this.telList.push({ tel: "", ext: "" });
        }
        this.form.optional_telephone = this.telList
          .map((item) => (item.ext ? `${item.tel}-${item.ext}` : item.tel))
          .join(",");
        this.isAddOptionalTelephone = false;
      }
    },
    deleteTel(index) {
      if (index == 0) {
        this.telList[0].tel = "";
        this.telList[0].ext = "";
        return;
      }
      this.telList.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-footer {
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px;
  box-shadow: 0 -10px 20px -5px #737373bf;
  button {
    width: 100%;
  }
}

::v-deep .nav-tabs {
  .nav-link {
    font-size: 14px !important;
  }
}
.icon-delete {
  width: 20px;
  height: 20px;
}
.register-panel {
  padding-bottom: 85px;
}
</style>
